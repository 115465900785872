<template>
  <div>
    <v-progress-circular
      v-if="!form"
      indeterminate
      size="50"
    ></v-progress-circular>
    <v-form
      :disabled="form.commission_id"
      v-if="form"
      ref="formSaveCollect"
      @submit.prevent="save"
    >
      <v-row>
        <v-col cols="12" md="2">
          <v-switch
            label="Frete à vista?"
            v-model="form.freight_paid"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-select
            label="Parceira"
            :items="partners"
            item-text="name"
            item-value="id"
            :loading="partners.length < 1"
            v-model="form.partner_id"
            :rules="requiredRules"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            label="Cidade"
            :items="cities"
            item-text="name"
            item-value="id"
            :loading="cities.length < 1"
            v-model="form.city_id"
            :rules="requiredRules"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            type="date"
            label="Data*"
            v-model="form.date"
            :rules="requiredRules"
            ref="dataInput"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :rules="requiredRules"
            label="CTe*"
            v-model="form.cte"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            :rules="requiredRules"
            label="NF*"
            v-model="form.nf"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Remetente (nome)*"
            v-model="form.sender"
            :rules="[
              ...requiredRules,
              (v) =>
                v.match(/\D/) ? true : 'Nome inválido (não digite o cnpj)',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Destinatário (nome)*"
            v-model="form.recipient"
            :rules="[
              ...requiredRules,
              (v) =>
                v.match(/\D/) ? true : 'Nome inválido (não digite o cnpj)',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Frete"
            v-model="form.freight_value"
            :rules="[
              (v) => !!v || 'Caso não tenha o valor do frete, preencha com 0',
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-text-field label="ICMS %" v-model="form.icms_value"></v-text-field>
        </v-col>
        <v-col cols="12" md="1">
          <v-text-field label="Pedágio" v-model="form.toll"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field label="Empresa" v-model="form.company"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field label="Código" v-model="form.code"></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Valor NF"
            :rules="requiredRules"
            v-model="form.nf_value"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Vol"
            :rules="requiredRules"
            v-model="form.qty"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            label="Peso"
            :rules="requiredRules"
            v-model="form.weight"
          ></v-text-field>
        </v-col>
        <v-col v-if="$auth.isAuthorized('manager')" cols="12" md="2">
          <v-text-field
            label="Comissão"
            v-model="form.commission_value"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            :loading="loading"
            :disabled="loading || form.commission_id"
            color="success"
            type="submit"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import partnersModule from "@/modules/partners";

export default {
  props: {
    form: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      partners: [],
      cities: [],
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  mounted() {
    this.getPartners();
    this.getCities();
  },
  methods: {
    save() {
      if (this.$refs.formSaveCollect.validate()) {
        this.$emit("save");
      }
    },
    handleResetValidation() {
      this.$refs.formSaveCollect.resetValidation();
      this.$refs.dataInput.focus();
    },
    getCities() {
      this.$http
        .get("api/cities")
        .then(({ data }) => (this.cities = data))
        .catch(() => this.$toast.error("Erro ao carregar cidades"));
    },
    async getPartners() {
      this.partners = await partnersModule();
    },
  },
};
</script>
