<template>
  <div>
    <h2>Digitar Coleta</h2>

    <form-collect
      ref="formCreateCollect"
      :loading="loading"
      @save="save"
      :form.sync="form"
    />
  </div>
</template>

<script>
import FormCollect from "./FormCollect";

export default {
  components: {
    FormCollect,
  },
  data() {
    return {
      show: false,
      loading: false,
      partners: [],
      saving: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
      form: {
        partner_id: "",
        date: new Date().toISOString().slice(0, 10),
        cte: "",
        nf: "",
        sender: "",
        recipient: "",
        freight_value: "",
        company: "",
        nf_value: "",
        qty: "",
        weight: "",
        code: "",
        city_id: "",
        commission_value: "",
        toll: "",
        freight_paid: "",
      },
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    getPartners() {
      this.$http
        .get("api/partners")
        .then((res) => {
          this.partners = res.data;
        })
        .catch((err) => {
          console.log(err);

          this.$toast.success("Erro ao carregar parceiras");
        });
    },
    cleanForm() {
      this.form.cte = "";
      this.form.nf = "";
      this.form.sender = "";
      this.form.recipient = "";
      this.form.freight_value = "";
      this.form.company = "";
      this.form.nf_value = "";
      this.form.qty = "";
      this.form.weight = "";
      this.form.commission_value = "";
      this.form.city_id = "";
      this.form.code = "";
      this.form.toll = "";
      this.form.icms_value = "";
    },
    async save() {
      this.loading = true;

      try {
        await this.$http.post("api/collects", this.form);
        this.$toast.success("Salvo com sucesso");
        this.cleanForm();
        this.$refs.formCreateCollect.handleResetValidation();
      } catch (error) {
        this.$toast.error("Erro ao salvar");
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
