import store from '@/store';
import http from '@/modules/http';

async function getPartners() {
  if(store.state.partners) {
    return store.state.partners;
  }

  try {
    let { data } = await http.get(`api/partners`);
    
    setPartners(data);
    return data;
  } catch (err) {
    console.log(err);
    alert('Erro ao carregar parceiras');

    return [];
  }
}

function setPartners(data) {
  store.dispatch('set_partners', data);
}

export default getPartners