<template>
  <div>
    <h1>Coletas</h1>

    <div class="d-flex flex-wrap my-4">
      <v-btn @click="action = 'import'" color="primary" class="ma-2">Importar</v-btn>
      <v-btn @click="action = 'create'" color="secondary" class="ma-2">Digitar</v-btn>
      <v-btn @click="action = null" color="error" class="ma-2">Listar</v-btn>
      <v-btn @click="$refs.indexCollects.getCollects()" color="secondary" class="ma-2">Recarregar</v-btn>
      <search-collect @search=handleSearch />
    </div>

    <hr>

    <div v-if="!action">
      <index ref="indexCollects" />
    </div>

    <div v-if="action == 'import'">
      <import />
    </div>

    <div v-if="action == 'create'">
      <create />
    </div>

  </div>
</template>

<script>

import Create from '../components/CreateCollect';
import Import from '../components/ImportCollect';
import Index from '../components/IndexCollect';
import SearchCollect from '../components/SearchCollect.vue';

export default {
  components: { 
    Import,
    Create,
    Index,
    SearchCollect,
  },
  data() {
    return {
      action: null,
    }
  },
  methods: {
    handleSearch(data) {
      this.$refs.indexCollects.handleFilter(data);
    }
  },
}
</script>

<style scoped>

</style>