<template>
  <div>
    <v-btn
      color="warning"
      class="ma-2"
      @click="searching = true"
    >
      Pesquisar
    </v-btn>
    <v-dialog v-model="searching">
      <v-card>
        <v-card-title>Pesquisar Coleta</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-form @submit.prevent="searchCte">
                <v-text-field
                  label="CTE"
                  v-model="cte"
                  append-outer-icon="mdi-magnify"
                  @click:append-outer="searchCte"
                ></v-text-field>
              </v-form>
            </v-col>
            
            <v-col cols="12" md="6">
              <v-form @submit.prevent="searchNf">
                <v-text-field
                  label="NF"
                  v-model="nf"
                  append-outer-icon="mdi-magnify"
                  @click:append-outer="searchNf"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searching: false,
        cte: null,
        nf: null,
      }
    },
    methods: {
      searchCte() {
        if(!this.cte) {
          return;
        }
        this.$http.get(`api/collects?cte=${this.cte}`)
          .then(({data}) => {
            this.cte = null;
            this.searching = false;
            this.$emit('search', data.data);
          }).catch(err => {
            console.log(err);
            this.$side({
              type    : 'error',
              msg     : 'Erro ao pesquisar',
              duration: 4000
            });
          });
      },
      searchNf() {
        if(!this.nf) {
          return;
        }
        this.$http.get(`api/collects?nf=${this.nf}`)
          .then(({data}) => {
            this.nf = null;
            this.searching = false;
            this.$emit('search', data.data);
          }).catch(err => {
            console.log(err);
            this.$side({
              type    : 'error',
              msg     : 'Erro ao pesquisar',
              duration: 4000
            });
          });
      }
    },
  }
</script>