<template>
	<div>
		<v-data-table
			:items="collects"
			:headers="headers"
			:page.sync="page"
			:loading="loading"
			disable-pagination
			hide-default-footer
			dense
		>
			<template v-slot:[`item`]="{ item }">
				<tr>
					<td>{{ item.partners.name }}</td>
					<td>{{ item.date | moment("DD/MM/YYYY") }}</td>
					<td>
						<a :href="`/#/coletas/${item.id}`" target="_blanck">
							{{ item.cte }}
						</a>
					</td>
					<td>{{ $functions.cutName(item.nf) }}</td>
					<td>{{ $functions.cutName(item.sender) }}</td>
					<td>{{ $functions.cutName(item.recipient) }}</td>
					<td>{{ item.icms_value }}</td>
					<td>{{ $functions.moneyFormat(item.freight_value) }}</td>
					<td>
						<v-icon :disabled="deleting" @click="deleteCollect(item.id)" color="red">mdi-delete</v-icon>
					</td>
				</tr>
			</template>
		</v-data-table>

		<div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="getCollects"
      ></v-pagination>
    </div>
	</div>
</template>

<script>

import moment from 'moment';

	export default {
		data() {
			return {
				loading: false,
				deleting: false,
				page: 1,
				pageCount: 0,
				collects: [],
				headers: [
					{text: 'Parceira', value: 'partners.name'},
					{text: 'Data', value: 'date'},
					{text: 'CTe', value: 'cte'},
					{text: 'NF', value: 'nf'},
					{text: 'Remetente', value: 'sender'},
					{text: 'Destinatário', value: 'recipient'},
					{text: 'ICMS', value: 'icms_value'},
					{text: 'Frete', value: 'freight_value'},
					{text: 'Ações', value: 'actions'},
				]
			}
		},
		mounted () {
			this.getCollects();
		},
		methods: {
			handleFilter(data) {
				this.collects = data;
			},
			deleteCollect(id) {
				if(confirm('Tem certeza que deseja excluir esta coleta?')) {
					this.deleting = true;
					this.$http.delete(`api/collects/${id}`)
					.then(() => {
						this.deleting = false;
						this.getCollects();
					}).catch(err => {
						this.deleting = false;
						console.log(err);

						this.$side({
							type: 'error',
							msg: 'Erro ao remover',
							duration: 4000,
						});
					});
				}
			},
			getCollects() {
				this.loading = true;
				this.collects = [];
				this.$http.get(`api/collects?page=${this.page}`)
				.then(res => {
					this.loading = false;
					this.pageCount = res.data.last_page;
					this.collects = res.data.data;
				}).catch(err => {
					this.loading = false;
					console.log(err);
					this.$side({
						type: 'error',
						msg: 'Erro ao carregar coletas',
						duration: 4000,
					});
				});
			},
		}
	}
</script>