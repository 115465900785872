<template>
  <div>
    <h2>Importar Coleta</h2>

    <v-file-input
      label="Selecione um arquivo"
      @change="handleFile"
      ref="inputCollectFile"
    ></v-file-input>

    <v-btn
      color="success"
      @click="saveFile"
    >Enviar</v-btn>
  </div>
</template>

<script>

import http from '@/modules/http';

  export default {
    data() {
      return {
        file: null,
      }
    },
    methods: {
      saveFile() {
        if(!this.file) {
          this.$side({
            type    : 'error',
            msg     : 'Nenhum arquivo selecionado!',
            duration: 4000
          });
          return
        }
        const data = new FormData();
        data.append('file', this.file);

        http.post('api/import-collect', data)
        .then(() => {
          this.$refs.inputCollectFile.reset();
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          });
        })
        .catch(err => {
          this.$side({
            type    : 'error',
            msg     : 'Erro ao salvar',
            duration: 4000
          });

          console.log(err);
        });

      },
      handleFile(e) {
        this.file = e;
      }
    },
  }
</script>